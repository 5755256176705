@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&display=swap');

html {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat";
  background-color: #041C32;
  width: 100%;
  height: 100%;
  color: #fff;
}

a {
  text-decoration: none;
  color: #fff;
}